import Header from "./component/header/index.vue";
import getMenuData from "@/component/back-layout/1.1.5/menuList";

import Footer from "./component/footer/index.vue";

export default {
  data() {
    return {
      oriNavList: [],
      navList: [],
    };
  },
  components: {
    Header,
    Footer,
  },
  created() {
    this.oriNavList = getMenuData();
    this.getMenuList();
  },
  mounted() {},
  activated() {},
  deactivated() {},
  destroyed() {},
  watch: {
    $route() {
      this.getMenuList();
    },
  },
  computed: {
    setBg() {
      let routeName = this.$route.name;
      if (routeName === "admin-travel-book-home") {
        // 当前页面为行程预定的首页
        return "home";
      } else if (routeName === "admin-train-search") {
        // 当前页是火车票搜索页
        return "train";
      } else if (routeName === "admin-flight-search") {
        // 当前页是机票搜索页
        return "flight";
      } else if (routeName === "admin-hotel-search") {
        return "hotel";
      } else {
        return "";
      }
    },
  },
  filters: {},
  methods: {
    getMenuList() {
      let navList = JSON.parse(JSON.stringify(this.oriNavList));
      navList = this.setActive(navList);
      this.navList = this.translateDataToTree(navList);
      // this.setMetaList()
    },
    setActive(dataList) {
      let activeId = "";
      dataList.forEach((value) => {
        if (value.metaName === this.$route.meta.lastMenu) {
          value.active = true;
          activeId = value.parentId;
        } else {
          value.active = false;
        }
      });
      let getParentActive = function(parentId) {
        if (parentId === 0) {
          return;
        }
        dataList.forEach((value) => {
          if (value.id === parentId) {
            value.active = true;
            getParentActive(value.parentId);
          }
        });
      };
      getParentActive(activeId);
      return dataList;
    },
    translateDataToTree(data) {
      const parents = data.filter(
        (value) =>
          value.parentId === 0 ||
          value.parentId === undefined ||
          value.parentId === null
      ); // 没有父节点的数据
      const children = data.filter((value) => value.parentId !== 0); // 有父节点的数据
      // 定义转换方法的具体实现
      const translator = (parents, children) => {
        parents.forEach((parent) => {
          children.forEach((current, index) => {
            if (current.parentId === parent.id) {
              const temp = JSON.parse(JSON.stringify(children));
              temp.splice(index, 1);
              translator([current], temp);
              typeof parent.children !== "undefined"
                ? parent.children.push(current)
                : (parent.children = [current]);
            }
          });
        });
      };
      translator(parents, children);
      return parents;
    },
  },
};
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
